import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
// import "swiper/swiper-bundle.css";
import Image from "../components/Image";
import Arrow from "../components/Arrow";

SwiperCore.use([Navigation]);

export const fragment = graphql`
  fragment SliderSection on WpPage_Pagesections_Sections_Slider {
    slides {
      srcSet
      sourceUrl
      altText
      id
      localFile {
        childImageSharp {
          gatsbyImageData(
            height: 640
            aspectRatio: 2.25
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      mimeType
    }
    link {
      url
      title
      target
    }
  }
`;

const zeroPad = (num, places) => String(num).padStart(places, "0");

const SliderSection = ({ slides, link }) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  return (
    <Swiper
      className="lg:container mx-auto"
      spaceBetween={0}
      navigation={{
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      }}
      slidesPerView={1}
      loop={true}
      onSlideChange={(e) => {
        setCurrentSlide(e.realIndex + 1);
      }}
    >
      <div className="absolute z-10 top-6 md:top-20 right-6 md:right-20 text-3xl font-bold">
        {zeroPad(currentSlide, 2)}
        <span className="text-teal">{`/${zeroPad(slides.length, 2)}`}</span>
      </div>
      {slides.map((slide, i) => {
        return (
          <SwiperSlide key={slide.id || i}>
            <Image image={slide} classes="h-72 md:h-auto" />
          </SwiperSlide>
        );
      })}
      <div className="absolute z-10 bottom-20 right-20 grid-cols-2 gap-4 hidden hover-hover:grid">
        <div className={`swiper-prev group cursor-pointer`}>
          <Arrow size={10} direction="left" theme="white" />
        </div>
        <div className={`swiper-next group cursor-pointer`}>
          <Arrow size={10} direction="right" theme="white" />
        </div>
      </div>
      {link && (
        <Link
          to={link.url}
          className="p-6 md:p-0 border-t-2 border-white border-opacity-10 md:border-t-0 md:absolute z-10 bottom-20 left-20 flex gap-4 items-center group"
        >
          <Arrow size={10} direction="right" />
          {link.title}
        </Link>
      )}
    </Swiper>
  );
};

export default SliderSection;
