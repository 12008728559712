import React, { useState } from "react";
import { Link } from "gatsby";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
// import "swiper/swiper-bundle.css";
import Image from "../components/Image";
import Arrow from "../components/Arrow";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faExpandArrows } from "@fortawesome/pro-light-svg-icons";

SwiperCore.use([Navigation]);

const zeroPad = (num, places) => String(num).padStart(places, "0");

const Slider = ({ slides, link, lightbox }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideImage, setCurrentSlideImage] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  return (
    <>
      <Swiper
        className="lg:container mx-auto h-full"
        spaceBetween={0}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        slidesPerView={1}
        loop={true}
        onSlideChange={(e) => {
          setCurrentSlide(e.realIndex + 1);
          setCurrentSlideImage(slides[e.realIndex].image);
        }}
      >
        <div className="absolute z-10 top-8 md:top-20 right-6 md:right-20 text-3xl font-bold">
          {zeroPad(currentSlide, 2)}
          <span className="text-teal">{`/${zeroPad(slides.length, 2)}`}</span>
        </div>
        {slides.map((slide, i) => {
          return (
            <SwiperSlide key={slide.id || i} className="relative">
              {!lightbox || (!lightbox.enable && <Image image={slide.image} />)}
              {lightbox && lightbox.enable && (
                // Lightbox enabled image
                <button
                  className="block h-full"
                  onClick={() => {
                    setPopupOpen(true);
                  }}
                >
                  <Image image={slide.image} classes="h-full" />
                </button>
              )}
              {lightbox && lightbox.enable && (
                <div className="absolute left-0 bottom-0 w-full z-10 bg-purple bg-opacity-80 py-6 px-6 md:px-16 items-center hidden hover-hover:flex">
                  <Button
                    classes="group"
                    text={lightbox.label}
                    icon={
                      <FontAwesomeIcon
                        icon={faExpandArrows}
                        className={`text-xl`}
                      />
                    }
                    onClick={() => {
                      setPopupOpen(true);
                    }}
                  />
                </div>
              )}
              {slide.link && (
                <div className="absolute left-0 bottom-0 w-full z-10 bg-purple bg-opacity-80 py-6 px-16 flex items-center">
                  <Button
                    text={slide.link.title}
                    url={slide.link.url}
                    icon={
                      <FontAwesomeIcon icon={faMap} className={`text-xl`} />
                    }
                  />
                </div>
              )}
            </SwiperSlide>
          );
        })}
        <div className="absolute z-10 bottom-7 right-6 md:right-10 grid-cols-2 gap-4 hidden hover-hover:grid">
          <div className={`swiper-prev group cursor-pointer`}>
            <Arrow size={10} direction="left" theme="white" />
          </div>
          <div className={`swiper-next group cursor-pointer`}>
            <Arrow size={10} direction="right" theme="white" />
          </div>
        </div>
        {link && (
          <Link
            to={link.url}
            className="absolute z-10 bottom-20 left-20 flex gap-4 items-center"
          >
            <Arrow size={10} direction="right" />
            {link.title}
          </Link>
        )}
      </Swiper>
      {lightbox && lightbox.enable && (
        <div
          className={`lb fixed inset-0 z-50 bg-black bg-opacity-60 place-items-center ${
            !popupOpen ? "hidden" : "grid"
          }`}
        >
          <button
            className="container mx-auto"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            {currentSlideImage && <Image image={currentSlideImage} />}
          </button>
        </div>
      )}
    </>
  );
};

export default Slider;
