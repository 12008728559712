import * as React from "react";

const Testimonial = ({ name, company, quote }) => {
  return (
    <div className="px-6 py-24 md:py-16 md:px-12 space-y-8 border-t-2 border-white border-opacity-10 md:border-t-0">
      <h2 className="font-bold text-3xl md:text-4xl max-w-prose">{quote}</h2>
      <div className="text-lg font-body">
        <h3 className="text-teal font-bold">{name}</h3>
        <h4>{company}</h4>
      </div>
    </div>
  );
};

export default Testimonial;
